<template>
  <c-box
    pos="relative"
    w="100%"
    h="100%"
    :pb="[isAuthenticated && isShowBottomNavbar ? '64px' : '0px', '0px']"
  >
    <Portal to="header">
      <HeaderComp
        :back-title="headerTitle"
        :back="headerBack"
        :with-back="headerWithBack"
        :notification="isShowNotification"
        :cart="isShowCart"
        :side-menu="isSideMenu"
        :list-menu-program="listProgram"
        @toggle="toggleSideMenu"
      />
    </Portal>
    <c-box
      :d="isAuthenticated ? 'flex' : 'block'"
      flex-direction="row"
      mx="auto"
      :max-width="isAuthenticated ? containerMaxWidth : 'unset'"
    >
      <SideMenu
        v-if="isAuthenticated && isSideMenu"
        :open="isOpenSideMenu"
        @updateOpen="updateSideMenu"
      />
      <c-box
        w="100%"
        :max-width="isAuthenticated ? (isSideMenu ? (isOpenSideMenu ? '1050px' : '1200px') : containerMaxWidth) : 'unset'"
        transition-duration="300ms"
        :pb="[isRouteProfile && isShowAlert ? '80px' : '0px', '0px']"
      >
        <portal-target name="breadcrumb" />
        <c-box
          :d="['block', isAuthenticated ? 'flex' : 'block']"
          :flex-grow="['unset', '1']"
          :my="['0px', isAuthenticated ? '1rem' : '0px']"
        >
          <transition
            name="route"
            mode="out-in"
          >
            <router-view />
            <!-- <router-view v-slot="{Component, route}" >
              <component :is="Component" :key="route.path"> </component>
            </router-view> -->
          </transition>
        </c-box>
      </c-box>
    </c-box>
    <FloatingChat v-if="!isAuthenticated || isShowFloatingChat" />
    <FooterComp v-if="isShowFooter" />
    <NavBottom v-if="isAuthenticated && userRole === 'client' && isShowBottomNavbar" />

    <c-box
      v-if="isAuthenticated && isShowAlert && (isHavingPendingNutritionist || isHavingPendingQuisionary)"
      pos="fixed"
      :bottom="['60px', '20px']"
      :right="[null, '20px']"
    >
      <c-alert status="warning">
        <c-alert-icon />
        <c-alert-description>
          {{
            isHavingPendingNutritionist
              ? 'Kamu masih belum memilih ahli gizi, yuk pilih ahli gizi agar dapat memulai program.'
              : 'Kamu punya formulir gizi yang belum selesai, mohon untuk melengkapinya'
          }}
          <c-button
            v-if="isHavingPendingNutritionist"
            size="sm"
            variant="ghost"
            variant-color="primary.400"
            as="router-link"
            :to="{
              name: 'client.nutritionists',
            }"
          >
            Pilih Ahli Gizi
          </c-button>
          <c-button
            v-else
            size="sm"
            variant="ghost"
            variant-color="primary.400"
            as="router-link"
            :to="{
              name: 'client.kuisioner',
              params: { programId: latestProgramId },
            }"
          >
            Lengkapi
          </c-button>
        </c-alert-description>
      </c-alert>
    </c-box>
  </c-box>
</template>

<script>
import HeaderComp from '@/components/header/index.vue'
import SideMenu from '@/views/nutritionists/_widgets/side-menu.vue'
import NavBottom from '@/components/nav-bottom.vue'
import FooterComp from '@/components/layouts/footer/index.vue'
import FloatingChat from '@/components/floating-chat.vue'
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'

export default {
  name: 'ClientLayout',
  components: {
    HeaderComp,
    SideMenu,
    NavBottom,
    FooterComp,
    FloatingChat,
  },
  data() {
    return {
      isOpenSideMenu: true,
      isShowBottomNavbar: true,
      isShowFooter: true,
      headerTitle: '', // header title
      headerBack: '', // header back route name
      headerWithBack: false, // header with back
      isShowNotification: true,
      isShowCart: true,
      isShowFloatingChat: false,
      breadcrumb: [],
      isShowAlert: true,
      isSideMenu: true,
      latestTransactionId: null,
      latestProgramId: null,
      listProgram: [],
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      getItemsQuantity: 'cart/getItemsQuantity',
      isHavingPendingNutritionist: 'clients/isHavingPendingNutritionist',
      isHavingPendingQuisionary: 'clients/isHavingPendingQuisionary',
      isFetchingPrograms: 'clients/isFetchingPrograms',
    }),
    ...mapState({
      userRole: (s) => s.auth?.user?.role,
    }),
    isRouteProfile() {
      if (this.$route.path.includes('profile')) return true
      return false
    },
    containerMaxWidth() {
      const routes = [
        'nutri.meal-plan.profile.edit',
        'nutri.meal-plan.meal-plan-schedule.edit',
        'nutri.meal-plan.menu-recommendation.edit',
      ]

      return routes.includes(this.$route.name) ? '1500px' : '1270px'
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.meta) {
          this.isShowBottomNavbar = route.meta.bottomNavbar ?? true
          this.isShowFooter = route.meta.footer ?? true
          this.headerTitle = route.meta.titleAlt ? route.meta.titleAlt : route.meta.title ? route.meta.title : ''
          this.headerBack = route.meta.back ? route.meta.back : {}
          this.headerWithBack = route.meta.withBack ?? false
          this.isShowNotification = route.meta.notification ?? true
          this.isShowCart = route.meta.cart ?? true
          this.isSideMenu = route.meta.sideMenu ?? true
          this.isShowFloatingChat = route.meta.floatingChat ?? false
          this.breadcrumb = route.meta.breadcrumb ? route.meta.breadcrumb : []
        }
        if (route.path.split('/').length < 3 && (route.meta.bottomNavbar ?? true)) {
          this.isShowAlert = true
        } else {
          this.isShowAlert = false
        }
      },
    },
    isFetchingPrograms: {
      handler(val) {
        if (val) {
          this.setFetchPrograms(false)
          this.checkPrograms()
        }
      },
    },
  },
  mounted() {
    this.initFetchListMenuProgram()
    this.checkPrograms()
  },
  methods: {
    ...mapActions({
      getProgramPageAllProduct: 'general/getProgramPageAllProduct',
      listRiwayatPrograms: 'profileProgram/listHistoryPrograms',
      getKuisioner: 'clients/getKuisioner',
    }),
    ...mapMutations({
      setPendingNutritionist: 'clients/setPendingNutritionist',
      setPendingQuisionary: 'clients/setPendingQuisionary',
      setCompletedQuisionary: 'clients/setCompletedQuisionary',
      setFetchPrograms: 'clients/setFetchPrograms',
    }),
    toggleSideMenu() {
      this.isOpenSideMenu = !this.isOpenSideMenu
    },
    updateSideMenu(value) {
      this.isOpenSideMenu = value
    },
    async checkPrograms() {
      if (this.isAuthenticated && this.userRole === 'client') {
        let programs = await this.listRiwayatPrograms()
        let latestProgram = programs?.length > 0 ? programs[0] : null

        if (latestProgram != null) {
          if (latestProgram.nutritionistId == null || latestProgram?.isChooseNutritionist) {
            this.setPendingNutritionist(true)
            this.latestTransactionId = latestProgram.orderId
          } else if (latestProgram?.isSkipQuestionnaire) {
            this.setPendingNutritionist(false)
            this.setPendingQuisionary(false)
            this.setCompletedQuisionary(true)
          } else {
            this.setPendingNutritionist(false)
            let kuisioner = await this.getKuisioner(latestProgram.id)
            this.$store.dispatch('kuisioner/updateCurrentStep', kuisioner?.step ?? 0)

            let isCompleted = kuisioner?.completedAt != null
            let isDraft = kuisioner?.isDraft ?? true

            if (!isCompleted && isDraft) {
              this.setPendingQuisionary(true)
              this.setCompletedQuisionary(false)
              this.latestProgramId = latestProgram.id
            } else {
              this.setPendingQuisionary(false)
              this.setCompletedQuisionary(true)
            }
          }
        } else {
          this.setPendingNutritionist(false)
          this.setPendingQuisionary(false)
          this.setCompletedQuisionary(false)
        }
      }
    },
    async initFetchListMenuProgram() {
      const res = await this.getProgramPageAllProduct()
      const filteredProgram = res.data.filter((program) => program.name !== 'For Enterprise')
      this.listProgram = filteredProgram
    },
  },
}
</script>

<style scoped>
.route-enter-from,
.route-leave-to {
  opacity: 0;
  transform: translateX(20px);
}
.route-enter-active,
.route-leave-active {
  transition: all 0.5s ease;
}
.route-enter-to,
.route-leave-from {
  opacity: 1;
  transform: translateX(0);
}
</style>
